import UserCard from '../../UserCard.vue';
import {ref, computed, getCurrentInstance, reactive, toRefs, onBeforeMount,onMounted, defineComponent, provide} from 'vue';
import {TableColumnCtx} from "element-plus/es/components/table/src/table-column/defaults";
import UserListUtil,{IUserListDataObj} from "@/views/sysviews/user/ts/userList/userListUtil";
import language from '../userLanguage'
const UserListHelper = defineComponent({
    name: "userList",
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        proxy.addLangProps(language);
        let dataObj:IUserListDataObj=reactive<IUserListDataObj>({
            utilInst:{} as UserListUtil,
            refMap:new Map(),
            importGuidParams:{
                ownerInst:proxy,
                uploadParams:{
                    saveType:0,
                    action:utils.Api.buildUrl('/user/importData')
                },
                downloadParams:{
                    sourceName:'userImportTemplate.xlsx',
                    downLoadFileName:proxy.$t('user.downLoadFileName'),
                    action:utils.Api.buildUrl('/user/downLoadResourceFile')
                }
            },
            pageListRef: {} as any,
            corpTreeData: [],
            deptTreeData: [],
            personData: [],
            pageList: {
                queryParam: { } as any,
                modelComp:UserCard,
                modelMethod: utils.Api.buildUrl("/user/pageData")
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new UserListUtil(proxy,dataObj);
        })
        onMounted(async ()=>{
            dataObj.corpTreeData = await utils.Api.corpTreeData();
            await dataObj.utilInst.requestDeptData('');
            await dataObj.utilInst.requestPersonData('','');
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'personId'==params.comboId){
                    return dataObj.personData
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=(newValue:string,selectId:string)=>{
            if('personId'==selectId){}
        }
        const formatterStatus=(row:any, column:TableColumnCtx<any>, value:any, index:number)=>{
            if (0 == value) {
                return proxy.$t('normal');
            } else if (1 == value) {
                return proxy.$t('stop');
            } else if (2 == value) {
                return proxy.$t('lock');
            } else {
                return "";
            }
        }
        //导入按钮事件
        const importHandler=()=>{
            dataObj.refMap.get('importGuid').importDataDialogVisible=true
        }
        return{
            ...toRefs(dataObj),comboSelect,selectOnChange,formatterStatus,importHandler
        }
    }
});
export default UserListHelper;