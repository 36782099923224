export default {
    zh:{
        userCard:'账号管理',
        user:{
            downLoadFileName:'帐号导入模版.xlsx',
            intiPwd:'初始密码为',
            queryParam:{
                corpId:'机构',
                corpId_placeholder:'请选择机构',
                deptId:'部门',
                deptId_placeholder:'请选择部门',
                personId:'人员',
                personId_placeholder:'请选择人员',
                code:'编码',
                code_placeholder:'请输入编码模糊匹配查询...',
                name:'名称',
                name_placeholder:'请输入名称模糊匹配查询...',
                status:'状态',
                status_placeholder:'请选择状态',
            },
            columns:{
                F_CODE:'编码',
                F_NAME:'名称',
                F_CORP_NAME:'单位',
                F_DEPT_NAME:'部门',
                F_PERSON_NAME:'人员',
                F_STATUS:'状态',
            },
            form:{
                code:'编码',
                code_placeholder:'请输入编码',
                code_rule:'请输入编码',
                name:'名称',
                name_placeholder:'请输入名称',
                personId:'所属人员',
                personId_placeholder:'请选择所属人员',
                personId_rule:'请选择所属人员',
                type:'账号类别',
                roleId:'角色',
                roleId_placeholder:'请选择角色',
                desc:'说明',
                desc_placeholder:'请输入说明',
            }
        }
    },
    en:{
        userCard:'user management',
        user:{
            downLoadFileName:'userImportTemplate.xlsx',
            intiPwd:'The initial password is',
            queryParam:{
                corpId:'corp',
                corpId_placeholder:'Please select organization',
                corpId_rule:'Please select organization',
                deptId:'department',
                deptId_placeholder:'Please select department',
                personId:'person',
                personId_placeholder:'Please select person',
                code:'code',
                code_placeholder:'Please enter a code fuzzy matching query...',
                name:'name',
                name_placeholder:'Please enter a name fuzzy matching query...',
                status:'status',
                status_placeholder:'Please select status',
            },
            columns:{
                F_CODE:'code',
                F_NAME:'name',
                F_CORP_NAME:'organization',
                F_DEPT_NAME:'department',
                F_PERSON_NAME:'person',
                F_STATUS:'status',
            },
            form:{
                code:'code',
                code_placeholder:'Please enter a code',
                code_rule:'Please enter a code',
                name:'name',
                name_placeholder:'Please enter a name',
                personId:'person',
                personId_placeholder:'Please select person',
                personId_rule:'Please select person',
                type:'type',
                roleId:'role',
                roleId_placeholder:'Please select role',
                desc:'explain',
                desc_placeholder:'Please enter explain',
            }
        }
    }
}