import {ref, computed, getCurrentInstance, reactive, toRefs, onBeforeMount,onMounted, defineComponent,nextTick} from 'vue';
import UserCardUtil,{IUserCardDataObj} from "@/views/sysviews/user/ts/userCard/userCardUtil";

export default defineComponent({
    name: "userCard",
    title: "账号管理",
    modelType:'card',
    fullscreen: false,
    setup(){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IUserCardDataObj=reactive<IUserCardDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            formRef:null,
            disabled:false,
            compParams: {
                modelPath: utils.Api.buildUrl("/user")
            },
            personData: [],
            roleData: [],
            form: {} as any,
            rules: utils.UtilPub.commonFormValidRule([{code:proxy.$t('user.form.code_rule')},{name:proxy.$t('user.form.name_rule')},{personId:proxy.$t('user.form.personId_rule')}]),
            otherParams:{
                disableField:false,
                roleDisable:true,
                showType:true
            }
        });
        onBeforeMount(()=>{dataObj.utilInst=new UserCardUtil(proxy,dataObj)})
        onMounted(()=>{

        })
        const beforeOpen=async(res:any,addOrLoad:string,cardEngine:any)=>{
            if('/load'==addOrLoad)dataObj.form.roleId=JSON.parse(res.data.roleId);
            nextTick(async() =>{
                await dataObj.utilInst.buildPersonData();
                await dataObj.utilInst.buildRoleData({addOrLoad:addOrLoad.substr(1),roleId:res.data.roleId});
            })
            dataObj.otherParams.disableField=res.disableField;
            if(res.disabled!=undefined)dataObj.disabled=res.disabled;
            if(res.roleDisable!=undefined)dataObj.otherParams.roleDisable=res.roleDisable;
        }
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'personId'==params.comboId){
                    return dataObj.personData;
                }
                if(params && 'roleId'==params.comboId){
                    return dataObj.roleData;
                }
                if(params && 'type'==params.comboId){
                    return [{label:'内部账号',value:0},{label:'经销商账号',value:1},{label:'合作方账号',value:2}];
                }
            }
        })

        const startHandler=()=>{
            dataObj.utilInst.changeStatus({id: dataObj.form.id,type:'start',operateType:'changeUserStatus'});
        }
        const stopHandler=()=>{
            dataObj.utilInst.changeStatus({id: dataObj.form.id,type:'stop',operateType:'changeUserStatus'});
        }
        const resetPwdHandler=()=>{
            dataObj.utilInst.resetPwd()
        }

        return{
            ...toRefs(dataObj),beforeOpen,comboSelect,startHandler,stopHandler,resetPwdHandler
        }
    }
});